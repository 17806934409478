import React from 'react';
import App from 'next/app';
import Head from 'next/head';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { CacheProvider } from '@emotion/react';
import CssBaseline from '@mui/material/CssBaseline';
import defaultTheme from '@stargazer/core-ui-v2/src/style/defaultTheme';
import getConfig from 'next/config';
import createEmotionCache from '../src/createEmotionCache';
import SnackbarProvider from '@stargazer/core-ui-v2/src/components/notifications/SnackProvider';
import AuthPage from '../src/components/AuthPage';

const { publicRuntimeConfig } = getConfig();
const clientSideEmotionCache = createEmotionCache();
const muiTheme = createTheme(defaultTheme);

import '../src/globalStyles.css';
import AppErrorBoundary from '@stargazer/core-ui-v2/src/components/AppErrorBoundary';

const MyApp = props => {
  const { Component, pageProps, router, emotionCache = clientSideEmotionCache } = props;

  return (
    <CacheProvider value={emotionCache}>
      <Head>
        <title>{publicRuntimeConfig.TITLE}</title>
        <link rel="shortcut icon" href="/static/favicon.ico" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0, user-scalable=no" />
      </Head>
      <ThemeProvider theme={muiTheme}>
        {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
        <CssBaseline />
        <AppErrorBoundary
          pageComponent={AuthPage}
          pageProps={{ fullWidth: 'true', justifyContent: 'center', logoType: 'long', title: 'Unexpected Error' }}
        >
          <SnackbarProvider>
            <Component {...pageProps} router={router} />
          </SnackbarProvider>
        </AppErrorBoundary>
      </ThemeProvider>
    </CacheProvider>
  );
};

class MyAppWrapper extends App {
  static async getInitialProps({ Component, ctx }) {
    let pageProps = {};

    // Retrieve getInitialProps() from the rendered Component
    if (Component.getInitialProps) {
      pageProps = await Component.getInitialProps({ ctx });
    }

    return { pageProps };
  }

  render() {
    return <MyApp {...this.props} />;
  }
}

export default MyAppWrapper;
