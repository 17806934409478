import React from 'react';
import Box from '@mui/material/Box';
import Copyright from '../../src/components/Copyright';
import PageContainer from '@stargazer/core-ui-v2/src/components/core/PageContainer';
import { useGetLayouts } from '@stargazer/core-ui-v2/src/hooks/useGetLayouts';
import BackButton from '@stargazer/core-ui-v2/src/components/core/BackButton';
import Link from 'next/link';

const AuthPage = ({
  logoType,
  title,
  backgroundImage,
  justifyContent,
  alignItems,
  backUrl,
  copyright,
  fullWidth,
  children,
}) => {
  const isDesktop = useGetLayouts('desktop');

  const styles = {
    main: {
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
    },
    content: {
      display: 'flex',
      flexDirection: 'column',
      flex: 1,
      justifyContent: isDesktop ? 'flex-start' : justifyContent || 'end',
      alignItems: isDesktop ? 'start' : alignItems || 'center',
      paddingTop: !backUrl && isDesktop && '140px',
      width: fullWidth ? 'auto' : (isDesktop && '50%') || 'auto',
    },
    backButton: {
      paddingTop: !fullWidth && isDesktop && '140px',
      paddingBottom: fullWidth && isDesktop && '140px',
      width: '100%',
    },
  };

  return (
    <PageContainer logoType={logoType} title={title} backgroundImage={backgroundImage}>
      <Box sx={styles.main}>
        {backUrl && (
          <Box sx={styles.backButton}>
            <Link href={backUrl} passHref>
              <BackButton>Back</BackButton>
            </Link>
          </Box>
        )}
        <Box sx={styles.content}>{children}</Box>
        {copyright && <Copyright />}
      </Box>
    </PageContainer>
  );
};

export default AuthPage;
