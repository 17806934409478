import React, { useMemo } from 'react';
import { Box, Container, Typography } from '@mui/material';
import Logo from '../Logo';
import { useGetLayouts } from '../../../hooks/useGetLayouts';
import { layout1styles, layout2styles } from './PageContainer.styles';
import Grid from '@mui/material/Grid';
import CircularProgress from '@mui/material/CircularProgress';
import Copyright from '../Copyright';

export const PageContainerLayout1 = ({ logoType, backgroundImage, children }) => {
  const isDesktop = useGetLayouts('desktop');
  const style = useMemo(() => layout1styles({ isDesktop, logoType, backgroundImage }), [isDesktop]);

  return (
    <Container sx={style.mainContainer}>
      {logoType && (
        <Box sx={style.logoBox}>
          <Logo logoType={logoType} sx={style.logo} />
        </Box>
      )}
      <Box sx={style.childrenBox}>{children}</Box>
    </Container>
  );
};

export const PageContainerLayout2 = ({ navBar, children, sideBar, loading, goHome }) => {
  const isDesktop = useGetLayouts('desktop');
  const logoType = isDesktop ? 'long' : 'short';
  const style = useMemo(() => layout2styles({ isDesktop, logoType }), [isDesktop]);

  const desktopView = (
    <Grid container>
      <Grid item xs={12} sx={style.headerBox}>
        {logoType && (
          <Box sx={style.logoBox}>
            <Logo logoType={logoType} sx={style.logo} onClick={goHome} />
            {/* {loading && <LinearProgress sx={style.loading} />} */}
            {loading && <CircularProgress size={24} />}
          </Box>
        )}
      </Grid>
      <Grid item xs={3}>
        <Box sx={style.navBarBox}>{navBar}</Box>
      </Grid>
      <Grid sx={style.contentBox} item xs={sideBar === 'hidden' ? 9 : 6}>
        {children}
      </Grid>
      {sideBar !== 'hidden' && (
        <Grid item xs={3}>
          <Box sx={style.sideBarBox}>{sideBar}</Box>
        </Grid>
      )}
      <Grid item xs={12} sx={{ textAlign: 'center', paddingTop: '10px', height: '90px' }}></Grid>
    </Grid>
  );

  const mobileView = (
    <>
      <Box sx={style.mainBox}>
        {logoType && (
          <Box sx={style.logoBox}>
            <Logo logoType={logoType} sx={style.logo} onClick={goHome} />
            {/* {loading && <LinearProgress sx={style.loading} />} */}
            {loading && <CircularProgress size={20} />}
          </Box>
        )}
        <Box sx={style.contentBox}>{children}</Box>
      </Box>
      <Box sx={style.navBarBox}>
        {sideBar !== 'hidden' && sideBar} {navBar}
      </Box>
    </>
  );

  return <Container sx={style.mainContainer}>{isDesktop ? desktopView : mobileView}</Container>;
};
