import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';

const Copyright = () => (
  <Box sx={{ height: '1vh', marginTop: '10px', textAlign: 'center' }}>
    <Typography variant="labelS" color="white" align="center">
      {'Copyright © '}
      <Link color="inherit" href="https://stargazer.co/" underline="hover">
        Stargazer.co
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  </Box>
);

export default Copyright;
