export const styles = {
  backButtonBox: {
    flex: '0 1 auto',
    alignSelf: 'start',
  },
  backButton: {
    display: 'flex',
    width: 'fit-content',
    height: '20px',
    color: 'white',
    verticalAlign: 'center',
    padding: '3px',
    margin: 0,
  },
};
