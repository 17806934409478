import React from 'react';
import { SnackbarProvider } from 'notistack';
import { styled } from '@mui/material';

const StyledSnackbarProvider = styled(SnackbarProvider)`
  &.SnackbarItem-variantSuccess {
    background: linear-gradient(45deg, #97e3af 0%, #67dad1 100%);
  }
  &.SnackbarItem-variantError {
    background: linear-gradient(45deg, #f0888d 0%, #f54c69 100%);
  }
`;

const CustomSnackbarProvider = props => {
  return (
    <StyledSnackbarProvider
      maxSnack={3}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      autoHideDuration={7000}
      {...props}
    />
  );
};

export default CustomSnackbarProvider;
