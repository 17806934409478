import React from 'react';
import { ReactComponent as ArrowLeft } from '../../../assets/svg/arrow-left-long.svg';
import Icon from './Icon';

const ArrowLeftLongIcon = ({ ...props }) => (
  <Icon {...props}>
    <ArrowLeft />
  </Icon>
);

export default ArrowLeftLongIcon;
