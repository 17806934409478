import { Box, Button, Typography } from '@mui/material';
import React from 'react';
import EmptyStateIcon from '../core/Icons/EmptyStateIcon';

const ErrorView = ({ emptyView, message, action, altAction, actionDesc, altActionDesc }) => {
  const styles = {
    main: {
      alignSelf: 'center',
      justifyContent: 'center',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      flex: 1,
    },
    inner: {
      gap: '15px',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'start',
      flex: 1,
      margin: '20px',
    },
    icon: {
      height: '120px',
      width: '120px',
    },
  };
  return (
    <Box sx={styles.main}>
      <Box sx={styles.inner}>
        <EmptyStateIcon sx={styles.icon} />
        <Typography variant="h5" color="white" align="center">
          {emptyView ? emptyView : 'Whoops, an Error oucurred!'}
        </Typography>
        <Typography variant="labelM" align="center" color={emptyView ? 'secondary' : 'error'}>
          {message}
        </Typography>
      </Box>
      {action && (
        <Button onClick={action} sx={{ justifySelf: 'end' }} variant="contained">
          {actionDesc || 'retry'}{' '}
        </Button>
      )}
      {altAction && (
        <Button variant="outlined" sx={{ justifySelf: 'end' }}>
          <Typography variant="primaryGradient"> {altActionDesc || 'go back'} </Typography>
        </Button>
      )}
    </Box>
  );
};

export default ErrorView;
