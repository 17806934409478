import React from 'react';
import { Link } from '@mui/material';
import ErrorView from '@stargazer/core-ui-v2/src/components/ErrorView';

class AppErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    console.error(error);
    console.log(errorInfo);
  }
  render() {
    if (this.state.hasError) {
      const PageComponent = this.props.pageComponent;
      return (
        <PageComponent {...this.props.pageProps}>
          <ErrorView
            emptyView={
              'An unexpected error occurred. Please, try reloading the page. If the problem persists, contact us for support.'
            }
            message={
              <Link href={'https://www.stargazer.co/contact'} target="_blank">
                Contact us
              </Link>
            }
          />
        </PageComponent>
      );
    }
    return this.props.children;
  }
}

export default AppErrorBoundary;
