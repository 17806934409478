let background_index = -1;
export const layout1styles = ({ isDesktop, backgroundImage }) => {
  if (typeof window === 'undefined') {
    background_index = 0;
  } else {
    background_index = (background_index + 1) % 5;
  }

  const backgrounds = {
    0: 'https://stargazer-platform.s3.amazonaws.com/assets/backgrounds/bg1.webp',
    1: 'https://stargazer-platform.s3.amazonaws.com/assets/backgrounds/bg2.webp',
    2: 'https://stargazer-platform.s3.amazonaws.com/assets/backgrounds/bg3.webp',
    3: 'https://stargazer-platform.s3.amazonaws.com/assets/backgrounds/bg4.webp',
    4: 'https://stargazer-platform.s3.amazonaws.com/assets/backgrounds/bg5.webp',
  };

  const background = isDesktop
    ? `linear-gradient(270deg, rgba(25, 27, 31, 1) 17%, rgba(25, 27, 31, 0.7) 42%, rgba(25, 27, 31, 1) 68%), url(${backgrounds[background_index]})`
    : `linear-gradient(180deg, #121212 1%, rgba(18, 18, 18, 0) 26.16%, #121212 76.23%), url(${backgrounds[background_index]})`;

  return {
    mainContainer: {
      display: 'flex',
      flexDirection: 'column',
      flexWrap: 'nowrap',
      justifyContent: 'flex-start',
      alignContent: 'stretch',
      alignItems: 'stretch',
      height: '100%',
      maxWidth: isDesktop ? '1200px' : 'auto',
      minWidth: isDesktop ? '1100px' : 'auto',
      background: backgroundImage && background,
      backgroundSize: backgroundImage && 'cover',
      backgroundPosition: backgroundImage && 'center',
      paddingLeft: '24px',
      paddingRight: '24px',
      paddingBottom: '24px',
    },
    logoBox: {
      order: 0,
      display: 'flex',
      flex: '0 1 auto',
      alignSelf: 'auto',
      minHeight: '100px',
      paddingTop: isDesktop ? '65px' : '25px',
      justifyContent: isDesktop ? 'start' : 'center',
    },
    childrenBox: {
      order: 2,
      flex: '1 1 auto',
      alignSelf: 'auto',
    },
    logo: {
      display: 'flex',
      height: '25px',
    },
  };
};

export const layout2styles = ({ isDesktop }) => {
  return {
    mainContainer: {
      maxWidth: isDesktop ? '1200px' : 'auto',
      minWidth: isDesktop ? '1100px' : 'auto',
      paddingTop: isDesktop ? '65px' : '25px',
      paddingRight: !isDesktop && '0px',
      paddingLeft: !isDesktop && '0px',
      display: !isDesktop && 'flex',
      flexDirection: !isDesktop && 'column',
      height: !isDesktop && '100%',
    },
    mainBox: {
      paddingLeft: '24px',
      paddingRight: '24px',
      paddingBottom: '24px',
      display: 'flex',
      flexDirection: 'column',
      flex: 1,
    },
    contentBox: {
      padding: isDesktop && '0 33px',
      display: 'flex',
      flexDirection: 'column',
      flex: 1,
    },
    headerBox: {
      position: 'sticky',
      top: isDesktop && '65px',
      bottom: !isDesktop && '0px',
    },
    logoBox: {
      display: 'flex',
      flex: '0 1 auto',
      alignSelf: 'auto',
      paddingBottom: isDesktop ? '40px' : '30px',
      justifyContent: 'start',
      zIndex: 10,
    },
    navBarBox: {
      position: 'sticky',
      top: isDesktop && '135px',
      bottom: !isDesktop && '0px',
    },
    sideBarBox: {
      position: isDesktop && 'sticky',
      top: isDesktop && '130px',
    },
    logo: {
      display: 'flex',
      height: '25px',
      flex: 1,
    },
    loading: {
      width: '40px',
      height: '5px',
      alignSelf: 'center',
    },
  };
};
