import React from 'react';
import { SvgIcon } from '@mui/material';

const Icon = ({ gradient, children, sx, ...props }) => (
  <SvgIcon sx={{ fill: gradient ? 'url(#gradient) !important' : '', ...sx }} {...props}>
    <defs>
      <linearGradient id="gradient">
        <stop stopColor="#A390F1" />
        <stop offset="1" stopColor="#EF8F8F" />
      </linearGradient>
    </defs>
    {children}
  </SvgIcon>
);

export default Icon;
