const gradients = {
  primary: 'linear-gradient(90deg, #A390F1 0%, #EF8F8F 100%)',
  success: 'linear-gradient(45deg, #97E3AF 0%, #67DAD1 100%)',
  error: 'linear-gradient(45deg, #F0888D 0%, #F54C69 100%)',
  warning: 'linear-gradient(45deg, #F0CD88 0%, #F56A4C 100%)',
  gray: 'linear-gradient(45deg, #45474B 0%, #262627 100%)',
};
const theme = {
  gradients: gradients,
  palette: {
    default: '#A390F1',
    secondary: { main: 'rgba(169, 166, 176, 1)' },
    primary: { main: '#c48fc4' },
    error: { main: '#F45870' },
    transparent: 'transparent',
    divider: '#4B4952',
    background: {
      default: '#fff',
      paper: '#fff',
    },
    text: {
      secondary: 'rgba(0, 0, 0, 0.5)',
      hint: 'rgba(0, 0, 0, 0.5)',
    },
  },

  typography: {
    fontFamily: 'DM Sans, sans-serif',
    fontSize: 16,
    default: {
      background: '#A390F1',
      WebkitBackgroundClip: 'text',
      WebkitTextFillColor: 'transparent',
    },
    primaryGradient: {
      background: gradients.primary,
      WebkitBackgroundClip: 'text',
      WebkitTextFillColor: 'transparent',
    },
    errorGradient: {
      background: gradients.error,
      WebkitBackgroundClip: 'text',
      WebkitTextFillColor: 'transparent',
    },
    warningGradient: {
      background: gradients.warning,
      WebkitBackgroundClip: 'text',
      WebkitTextFillColor: 'transparent',
    },
    successGradient: {
      background: gradients.success,
      WebkitBackgroundClip: 'text',
      WebkitTextFillColor: 'transparent',
    },
    //Headings
    h1: {
      fontSize: '2.5em',
      fontWeight: 700,
      lineHeight: '2.5em',
    },
    h2: {
      fontSize: '1.5em',
      lineHeight: '2em',
      fontWeight: 700,
    },
    h3: {
      fontSize: '1.25em',
      lineHeight: '1.5em',
      fontWeight: 700,
    },
    h4: {
      fontSize: '1em',
      fontWeight: 700,
      lineHeight: '1.25em',
    },

    //Bodys
    bodyM: {
      fontSize: '0.875em',
      lineHeight: '1.5em',
      fontWeight: 400,
    },
    bodyM_Highlight: {
      fontSize: '0.875em',
      lineHeight: '1.5em',
      fontWeight: 500,
    },
    bodyS: {
      fontSize: '0.75em',
      lineHeight: '1.25em',
      fontWeight: 400,
    },
    bodyXS: {
      fontSize: '0.65em',
      lineHeight: '1.25em',
      fontWeight: 400,
    },
    bodyS_Highlight: {
      fontSize: '0.75em',
      lineHeight: '1.25em',
      fontWeight: 500,
    },
    bodyS_Underline: {
      fontSize: '0.75em',
      lineHeight: '1.25em',
      textDecoration: 'underline',
      fontWeight: 500,
    },

    //Labels
    labelM: {
      fontSize: '0.875em',
      lineHeight: '0.875em',
      fontWeight: 400,
    },
    labelS: {
      fontSize: '0.75em',
      lineHeight: '0.75em',
      fontWeight: 400,
    },
    labelS_Highlight: {
      fontSize: '0.75em',
      lineHeight: '0.75em',
      fontWeight: 500,
    },
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        'input, select': {
          //OVERRIDES AUTOCOMPLETE COLLOR INPUT CHANGES...
          color: '#fff !important',
          WebkitTextFillColor: '#fff !important',
          WebkitBackgroundClip: 'text !important',
          backgroundClip: 'text !important',
        },
        '#__next': {
          height: '100%',
        },
        html: {
          height: '100%',
        },
        body: {
          height: '100%',
          letterSpacing: '0.01071em',
          background: '#191B1F' /*Neutral 700*/,
          '&::-webkit-scrollbar, & *::-webkit-scrollbar': {
            backgroundColor: '#2b2b2b',
            width: '12px',
          },
          '&::-webkit-scrollbar-thumb, & *::-webkit-scrollbar-thumb': {
            backgroundColor: '#9b759b',
            minHeight: '10px',
          },
          '&::-webkit-scrollbar-thumb:focus, & *::-webkit-scrollbar-thumb:focus': {
            backgroundColor: '#A390F1',
          },
          '&::-webkit-scrollbar-thumb:active, & *::-webkit-scrollbar-thumb:active': {
            backgroundColor: '#A390F1',
          },
          '&::-webkit-scrollbar-thumb:hover, & *::-webkit-scrollbar-thumb:hover': {
            backgroundColor: '#c48fc4',
          },
        },
      },
    },
    MuiDialog: {
      styleOverrides: {
        paper: {
          background: '#222528' /*Neutral 300*/,
          color: 'white',
        },
      },
    },
    MuiSkeleton: {
      styleOverrides: {
        root: {
          backgroundColor: '#222528',
          '&::after': {
            background: 'linear-gradient(90deg,  #222528, #51394b, rgba(34, 37, 40, 0.8) );',
          },
        },
      },
    },
    MuiCircularProgress: {
      styleOverrides: {
        root: {
          color: '#c48fc4',
        },
      },
    },
    MuiDivider: {
      styleOverrides: {
        root: {
          margin: '10px 0px',
        },
      },
    },
    MuiCheckbox: {
      styleOverrides: {
        root: {
          color: 'transparent',
          '&::before': {
            position: 'absolute',
            top: 13,
            left: 13,
            right: 13,
            bottom: 13,
            background: gradients.primary,
            content: '""',
            zIndex: -2,
            borderRadius: '4px',
          },
          '&::after': {
            position: 'absolute',
            top: 14,
            left: 14,
            right: 14,
            bottom: 14,
            background: '#222528',
            content: '""',
            zIndex: -2,
            borderRadius: '4px',
          },
          '&.Mui-disabled': {
            color: 'transparent',
            '&::before': {
              position: 'absolute',
              top: 22,
              left: 17.5,
              height: '2px',
              width: '10px',
              background: gradients.primary,
              content: '""',
              zIndex: -1,
              borderRadius: '4px',
            },
            '&::after': {
              position: 'absolute',
              top: 12,
              left: 12,
              right: 12,
              bottom: 12,
              background: '#222528',
              content: '""',
              zIndex: -2,
              borderRadius: '4px',
            },
          },
          '&.Mui-checked': {
            '.MuiSvgIcon-root': {
              padding: '4px',
            },
            color: 'white',
            '&::after': {
              position: 'absolute',
              top: 13,
              left: 13,
              right: 13,
              bottom: 13,
              background: gradients.primary,
              content: '""',
              zIndex: -1,
              borderRadius: '4px',
            },
          },
        },
      },
    },
    MuiBottomNavigationAction: {
      styleOverrides: {
        label: {
          '&.Mui-selected': {
            fontWeight: 500,
            fontSize: '0.75em',
            lineHeight: '0.75em',
            color: '#ffffff',
          },
          fontSize: '0.75em',
          lineHeight: '0.75em',
          fontWeight: 300,
        },
        root: {
          overflow: 'hidden', //if you want to stop the navLight from going outside the navigation bar
          borderTop: '1px solid #4B4952',
          '.MuiTouchRipple-root': {
            color: '#EF8F8F',
          },
          '.MuiSvgIcon-root': {
            padding: '5px',
          },
          '.navLight': {
            borderRadius: '100%',
            position: 'absolute',
            width: '28px',
            height: '28px',
            top: '-12px',
            background: 'linear-gradient(90deg, #A390F1 0%, #EF8F8F 100%)',
            filter: 'blur(15px)',
          },
          '&.Mui-selected': {
            color: '#85828F',
          },
          color: '#85828F',
        },
      },
    },
    MuiBottomNavigation: {
      styleOverrides: {
        root: {
          backgroundColor: '#191B1F',
          color: '#85828F',
        },
      },
    },
    MuiAccordionSummary: {
      styleOverrides: {
        expandIconWrapper: {
          color: '#ffffff',
        },
        root: {
          zIndex: 2,
          color: '#ffffff',
          fontSize: '1em',
          fontWeight: 500,
          lineHeight: '1.25em',
        },
      },
    },
    MuiAccordionDetails: {
      styleOverrides: {
        root: {
          color: '#A9A6B0',
          fontSize: '0.875em',
          lineHeight: '1.5em',
          fontWeight: 300,
        },
      },
    },
    MuiAccordion: {
      styleOverrides: {
        root: {
          padding: '5px',
          background: '#222528',
          position: 'relative',
          border: '1px solid #45474B',
          backgroundClip: 'padding-box',
          borderRadius: '8px',
          '&::before': {
            backgroundColor: 'transparent',
          },
          '&.Mui-expanded': {
            margin: 0,
          },
        },
      },
    },
    MuiLink: {
      styleOverrides: {
        root: {
          color: '#FF82A4',
          fontSize: '0.75em',
          lineHeight: '1.25em',
          textDecorationColor: '#ff82a454',
          '&:disabled': {
            color: '#85828F',
            textDecorationColor: '#85828F',
            cursor: 'default',
          },
        },
      },
      variants: [
        {
          props: { variant: 'bodyXS' },
          style: {
            fontSize: '0.65em',
            lineHeight: '1.25em',
            fontWeight: 300,
          },
        },
      ],
    },
    MuiIcon: {
      styleOverrides: {
        root: {
          fontSize: 'small',
        },
      },
    },
    MuiCard: {
      styleOverrides: {
        root: {
          border: '1px solid #45474B',
          background: '#222528',
          padding: '20px',
        },
      },
    },
    MuiCardContent: {
      styleOverrides: {
        root: {
          fontSize: '1em',
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        body: {
          fontSize: '1em',
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          color: 'white',
          fontSize: '0.875em',
          lineHeight: '1.5em',
          textShadow: '0px 1px 3px black',
          fontWeight: 300,
          '&.Mui-disabled': {
            color: '#696673',
          },
          '&.Mui-focused, &.MuiFormLabel-filled': {
            fontWeight: 600,
          },
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          '&.MuiOutlinedInput-root': {
            margin: 0,
          },
          '& input[type=number]': {
            '-moz-appearance': 'textfield',
          },
          '& input[type=number]::-webkit-outer-spin-button': {
            '-webkit-appearance': 'none',
            margin: 0,
          },
          '& input[type=number]::-webkit-inner-spin-button': {
            '-webkit-appearance': 'none',
            margin: 0,
          },
        },
      },
    },

    MuiOutlinedInput: {
      styleOverrides: {
        notchedOutline: {
          borderRadius: '8px',
          border: '0px solid transparent',
        },
        input: {
          fontSize: '0.875em',
          lineHeight: '1.5em',
          fontWeight: 300,
          '&.Mui-disabled': {
            '-webkit-text-fill-color': '#A9A6B0',
          },
        },
        root: {
          boxShadow: '0px 1px 5px #00000073',
          color: 'white',
          fontSize: '0.875em',
          lineHeight: '1.5em',
          fontWeight: 300,
          '&.Mui-focused': {
            background: '#191B1F',
          },
          border: '0px solid transparent',
          backgroundClip: 'padding-box',
          background: '#222528',
          borderRadius: '8px',
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          paddingTop: 12,
          paddingBottom: 12,
        },
      },
    },
    MuiChip: {
      styleOverrides: {
        root: {
          fontSize: '0.875em',
          color: '#0E0E0E',
          borderRadius: '8px',
        },
        filledRounded: {
          borderRadius: '16px',
        },
        outlinedRounded: {
          borderRadius: '16px',
          position: 'relative',
          border: '0px solid transparent',
          backgroundClip: 'padding-box',
          background: '#191B1F',
          '&::before': {
            position: 'absolute',
            top: -1,
            left: -1,
            right: -1,
            bottom: -1,
            background: 'linear-gradient(90deg, #806BD3 0%, #DC7171 100%)',
            content: '""',
            zIndex: -1,
            borderRadius: '16px',
          },
        },
        outlined: {
          position: 'relative',
          border: '0px solid transparent',
          backgroundClip: 'padding-box',
          background: '#191B1F',
          '&::before': {
            position: 'absolute',
            top: -1,
            left: -1,
            right: -1,
            bottom: -1,
            background: 'linear-gradient(90deg, #806BD3 0%, #DC7171 100%)',
            content: '""',
            zIndex: -1,
            borderRadius: '8px',
          },
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: 'none',
          fontSize: '0.875em',
          color: '#0E0E0E',
          border: 20,
          width: '100%',
          height: '48px',
          margin: '10px 0px',
          borderRadius: '8px',
        },
        text: {
          color: '#FF82A4',
          '&:disabled': {
            color: '#4B4952',
          },
        },
        textSizeSmall: {
          fontSize: '12px',
        },
        containedBox: {
          boxShadow: '0px 0px 8px rgba(239, 143, 195, 0.6)',
          background: 'linear-gradient(90deg, #A390F1 0%, #EF8F8F 100%)',
          '&:disabled': {
            background: '#222528',
            color: '#4B4952',
          },
          '@media (hover: hover)': {
            '&:hover': {
              background: 'linear-gradient(90deg, #806BD3 0%, #DC7171 100%)',
              position: 'relative',
              border: '2px solid transparent',
              backgroundClip: 'padding-box',
              '&::before': {
                position: 'absolute',
                top: -2,
                left: -2,
                right: -2,
                bottom: -2,
                background: 'linear-gradient(90deg, #A390F1 0%, #EF8F8F 100%)',
                content: '""',
                zIndex: -1,
                borderRadius: '8px',
              },
            },
          },
        },
        contained: {
          boxShadow: '0px 0px 8px rgba(239, 143, 195, 0.6)',
          background: 'linear-gradient(90deg, #A390F1 0%, #EF8F8F 100%)',
          borderRadius: '2000px',
          '&:disabled': {
            background: '#222528',
            color: '#4B4952',
          },
          '@media (hover: hover)': {
            '&:hover': {
              background: 'linear-gradient(90deg, #806BD3 0%, #DC7171 100%)',
              position: 'relative',
              border: '2px solid transparent',
              backgroundClip: 'padding-box',
              '&::before': {
                position: 'absolute',
                top: -2,
                left: -2,
                right: -2,
                bottom: -2,
                background: 'linear-gradient(90deg, #A390F1 0%, #EF8F8F 100%)',
                content: '""',
                zIndex: -1,
                borderRadius: '2000px',
              },
            },
          },
        },
        outlinedBox: {
          boxShadow: '0px 0px 10px rgba(239, 143, 195, 0.6)',
          position: 'relative',
          border: '0px solid transparent',
          backgroundClip: 'padding-box',
          background: '#191B1F',
          '&::before': {
            position: 'absolute',
            top: -2,
            left: -2,
            right: -2,
            bottom: -2,
            background: 'linear-gradient(90deg, #806BD3 0%, #DC7171 100%)',
            content: '""',
            zIndex: -1,
            borderRadius: '8px',
          },
          '&:hover': {
            border: '0px solid transparent',
            background: '#222528',
          },
          '&:disabled': {
            position: 'relative',
            border: '0px solid transparent',
            backgroundClip: 'padding-box',
            borderRadius: '8px',
            color: '#4B4952',
            '&::before': {
              position: 'absolute',
              top: -2,
              left: -2,
              right: -2,
              bottom: -2,
              background: gradients.gray,
              borderRadius: '8px',
            },
          },
        },
        outlined: {
          borderRadius: '2000px',
          position: 'relative',
          border: '0px solid transparent',
          backgroundClip: 'padding-box',
          background: '#191B1F',
          '&::before': {
            position: 'absolute',
            top: -2,
            left: -2,
            right: -2,
            bottom: -2,
            background: 'linear-gradient(90deg, #806BD3 0%, #DC7171 100%)',
            content: '""',
            zIndex: -1,
            borderRadius: '2000px',
          },
          '&:hover': {
            border: '0px solid transparent',
            background: '#222528',
          },
          '&:disabled': {
            position: 'relative',
            border: '0px solid transparent',
            backgroundClip: 'padding-box',
            borderRadius: '2000px',
            color: '#4B4952',
            '&::before': {
              position: 'absolute',
              top: -2,
              left: -2,
              right: -2,
              bottom: -2,
              background: gradients.gray,
              borderRadius: '2000px',
            },
          },
        },
      },
    },
  },
};
export default theme;
