import React from 'react';
import LogoLong from '../../../assets/svg/stargazer-logo.svg';
import LogoShort from '../../../assets/svg/stargazer-logo-short.svg';
import Box from '@mui/material/Box';

const Logo = ({ logoType, ...props }) => {
  if (logoType === 'short')
    return (
      <Box {...props}>
        <img src={LogoShort} alt="Stargazer" />
      </Box>
    );

  return (
    <Box {...props}>
      <img style={{ cursor: 'pointer' }} src={LogoLong} alt="Stargazer" />
    </Box>
  );
};

export default Logo;
