import React from 'react';
import { PageContainerLayout1, PageContainerLayout2 } from './PageContainerView';

const PageContainer = ({ layout = 1, logoType, backgroundImage, navBar, sideBar, loading, children, goHome }) => {
  return (
    (layout === 1 && (
      <PageContainerLayout1 logoType={logoType} backgroundImage={backgroundImage}>
        {children}
      </PageContainerLayout1>
    )) ||
    (layout === 2 && (
      <PageContainerLayout2 goHome={goHome} loading={loading} navBar={navBar} sideBar={sideBar}>
        {children}
      </PageContainerLayout2>
    ))
  );
};

export default PageContainer;
