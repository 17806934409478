import React from 'react';
import { styles } from './BackButton.styles';
import { Box } from '@mui/material';
import Button from '@mui/material/Button';
import ArrowLeftLongIcon from '../Icons/ArrowLeftLongIcon';

const BackButton = React.forwardRef(({ backAction, href, children, ...args }, ref) => {
  return (
    <Box sx={styles.backButtonBox}>
      <Button
        onClick={backAction}
        href={href}
        sx={styles.backButton}
        variant="text"
        size="small"
        startIcon={<ArrowLeftLongIcon />}
        ref={ref}
        {...args}
      >
        {children}
      </Button>
    </Box>
  );
});

export default BackButton;
