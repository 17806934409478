import React from 'react';
import { SvgIcon } from '@mui/material';
import { ReactComponent as EmptyState } from '../../../assets/svg/empty-state.svg';

const EmptyStateIcon = ({ ...props }) => (
  <SvgIcon viewBox="0 0 120 120" {...props}>
    <EmptyState />
  </SvgIcon>
);

export default EmptyStateIcon;
